<template>
  <Nav :changes="list" />
  <div class="headG">
    <h1 class="titleG">{{ course.vidTitle }}</h1>
    <p class="subtitleG">{{ course.vidSubtitle }}</p>
  </div>
  <div class="cardG">
    <h3>Guided E-Learning</h3>
    <p class="course-subtitle">
      This section is structured to allow for discussion at key points in this
      patient's treatment history. It is ideal for individual and small group
      interactions.
    </p>
    <Accordion
      :activeIndex="active"
      @tab-close="tabClose($event)"
      @tab-open="tabOpen($event)"
    >
      <AccordionTab v-for="(courseTab, ind) in course.tabs" :key="ind">
        <template #header>
          <div
            style="display: flex; justify-content: space-between; width: 100%;"
          >
            <span>{{ courseTab.head }}</span>
            <span v-if="download === ind && download < course.tabs.length - 1">
              <Button
                class="p-button-info p-button-outlined"
                @click="downloadSlide"
                >Download</Button
              >
            </span>
          </div>
        </template>
        <div v-if="ind === course.tabs.length - 1">
          <p>Well Done! You've completed this module</p>
        </div>
        <div v-else>
          <vimeo-player
            :video-id="courseTab.video"
            :ref="vids[ind]"
            @ended="checked(ind)"
            @play="addVid(ind)"
            :options="{ responsive: true }"
            class="embed-container"
          ></vimeo-player>
          <!-- <span
            ><input
              type="checkbox"
              @click="checked(ind)"
              :disabled="disabledCheck[ind]"
              :checked="disabledCheck[ind]"
            />
            <p>I completed this Section</p>
          </span> -->
        </div>
      </AccordionTab>
    </Accordion>
  </div>
  <div class="footer">
    <div class="footer-card">
      <div class="presentation-title">
        <h3>{{ course.presentation }}</h3>
        <Button class="p-button-info" @click="downloadFullVideo"
          >Download</Button
        >
      </div>
      <div>
        <vimeo-player
          :video-id="`${course.presentationVid}`"
          :options="{ responsive: true }"
          class="embed-footer"
        ></vimeo-player>
      </div>
    </div>
    <!-- <div class="footer-card">
      <h3>{{ course.story }}</h3>
      <div>
        <vimeo-player
          :video-id="`https://player.vimeo.com/video/${course.storyVid}`"
          :options="{ responsive: true }"
          class="embed-footer"
        ></vimeo-player>
      </div>
    </div> -->
  </div>
  <Foot />
</template>

<script>
import { onMounted, ref } from "vue";
import Nav from "../components/nav";
import courses from "../modules/data";
import updateSeen from "../modules/updateSeen";
import getDocument from "../modules/getDocument";
import { auth } from "../firebase/config";
export default {
  components: { Nav },
  props: ["number"],
  setup(props) {
    const active = ref(null);
    const download = ref(null);
    const list = [
      { name: "Case List" },
      { name: "My Profile" },
      { name: "Logout" },
    ];
    const tabClose = (e) => {
      if (e.index !== course.tabs.length - 1) {
        vids[e.index].value.pause();
      }
      download.value = null;
    };
    const tabOpen = (e) => {
      download.value = e.index;
    };
    const downloadSlide = () => {
      // console.log(course.tabs[d]);
      window.open(course.tabs[download.value].downloadLink);
    };
    // const disabledCheck = ref([null, null, null, null, null, null]);
    const vids = [
      ref(null),
      ref(null),
      ref(null),
      ref(null),
      ref(null),
      ref(null),
      ref(null),
      ref(null),
      ref(null),
      ref(null),
      ref(null),
    ];
    // const disableAccord = ref([false, true, true, true, true, true, true]);
    const course = courses[props.number];
    const addVid = async (e) => {
      const { data } = await getDocument(auth.currentUser.email, false);
      const x = data.value.course[props.number];
      if (!x.includes(e + 1)) {
        const { seenVid } = await updateSeen(props.number, e + 1);
      }
    };
    const checked = (e) => {
      vids[e].value.pause();
      // disabledCheck.value[e] = true;
      // disableAccord.value[e + 1] = false;
      active.value = e + 1;
    };
    const downloadFullVideo = () => {
      window.open(course.downloadPresentation);
    };
    onMounted(async () => {
      const { seenVid } = await updateSeen(props.number, null);
      // disableAccord.value = disableAccord.value.map((event, index) => {
      //   if (index <= seenVid.value) {
      //     return false;
      //   } else {
      //     return true;
      //   }
      // });
      // disabledCheck.value = disabledCheck.value.map((event, index) => {
      //   if (index < seenVid.value) {
      //     return true;
      //   } else {
      //     return null;
      //   }
      // });
    });
    return {
      active,
      checked,
      course,
      vids,
      tabClose,
      addVid,
      list,
      download,
      tabOpen,
      downloadSlide,
      downloadFullVideo,
    };
  },
};
</script>

<style scoped>
.presentation-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
}
.presentation-title > h3 {
  margin-bottom: 0;
}
.embed-container {
  width: 100% !important;
}
/* .embed-footer {
  padding: 0 3%;
} */
h3 {
  text-align: left;
  margin: 0 0 5px 10px;
}
span {
  display: flex;
  align-items: center;
}
span * {
  display: inline-block;
  width: fit-content;
  margin: 3px;
  font-size: 0.8rem;
}
.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 20px 30px;
}
.footer-card {
  width: 40%;
  margin: 0 20px 20px;
  min-height: 350px;
  padding: 10px;
  background-color: var(--card);
  border-radius: 5px;
  box-shadow: 2px 2px 10px rgb(27, 19, 99);
}
.course-subtitle {
  font-family: "Archivo Narrow", sans-serif;
  font-size: 1.2rem;
  text-align: left;
  margin: 0 2px 10px;
}
@media (max-width: 1300px) {
  .footer {
    display: block;
  }
  .footer-card {
    width: 80%;
    margin: 0 auto 50px;
  }
  .footer-item {
    padding-top: 60px;
  }
}
@media (max-width: 620px) {
  .cardG {
    bottom: 0;
    width: 100%;
  }
  .titleG {
    font-size: 1.8rem;
  }
  .subtitleG {
    font-size: 0.8rem;
  }
  .footer-card {
    width: 100%;
    margin: 20px auto 20px;
  }
}
@media (max-width: 520px) {
  .footer-card {
    min-height: 300px;
  }
}
@media (max-width: 420px) {
  .footer-card {
    min-height: 250px;
  }
}
@media (max-width: 310px) {
  .footer-card {
    min-height: 200px;
  }
}
</style>
